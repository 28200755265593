import request from '@/utils/request'
const evses = '/cms/evses'
class AssetApi {
  chargePoints = '/cms/charge_points'
  locations = '/cms/locations'
  /**
   * location
   * **/
  getLocationSetup = () => {
    return request({
      method: 'GET',
      url: '/cms/locations/setup'
    })
  }

  getLocationList = (params) => {
    return request({
      method: 'GET',
      url: '/cms/locations',
      params
    })
  }

  getLocationPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/locations/${id}/preview`
    })
  }

  getLocationAdd = (data) => {
    return request({
      method: 'POST',
      url: '/cms/locations',
      data
    })
  }

  getLocationEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/locations/${id}`,
      data
    })
  }

  getLocationDtails = (id) => {
    return request({
      method: 'GET',
      url: `${this.locations}/${id}`
    })
  }

  deleteLocationRecord = (id) => {
    return request({
      method: 'DELETE',
      url: `${this.locations}/${id}`
    })
  }

  getLocationSimpleList = (id) => {
    return request({
      method: 'GET',
      url: `${this.locations}/simple_list`
    })
  }

  /**
   * charge point
   * **/
  getChargePointSetup = (params) => {
    return request({
      method: 'GET',
      url: '/cms/charge_points/setup',
      params
    })
  }

  getChargePointList = (params) => {
    return request({
      method: 'GET',
      url: '/cms/charge_points',
      params
    })
  }

  getChargePointPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/charge_points/${id}/preview`
    })
  }

  getChargePointAdd = (data) => {
    return request({
      method: 'POST',
      url: '/cms/charge_points',
      data
    })
  }

  getChargePointEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/charge_points/${id}`,
      data
    })
  }

   getChargePointSimpleList = () => {
     return request({
       method: 'GET',
       url: '/cms/charge_points/simple_list'
     })
   }

  getChargePointtails = (id) => {
    return request({
      method: 'GET',
      url: `${this.chargePoints}/${id}`
    })
  }

  getChargePointStats = () => {
    return request({
      method: 'GET',
      url: `${this.chargePoints}/stats`
    })
  }

  deleteChargePointRecord = (id) => {
    return request({
      method: 'DELETE',
      url: `${this.chargePoints}/${id}`
    })
  }

  /**
   * EVSEs
   * **/
   getEvsesSetup = () => {
     return request({
       method: 'GET',
       url: `${evses}/setup`
     })
   }

   getEvsesList = (params) => {
     return request({
       method: 'GET',
       url: evses,
       params
     })
   }

   getEvsesPreview = (id) => {
     return request({
       method: 'GET',
       url: `${evses}/${id}/preview`
     })
   }

   getEvsesAdd = (data) => {
     return request({
       method: 'POST',
       url: evses,
       data
     })
   }

   getEvsesEdit = (id, data) => {
     return request({
       method: 'PUT',
       url: `${evses}/${id}`,
       data
     })
   }

   deleteEvsesRecord = (id) => {
     return request({
       method: 'DELETE',
       url: `${evses}/${id}`
     })
   }

   getEvsesdtails = (id) => {
     return request({
       method: 'GET',
       url: `${evses}/${id}`
     })
   }

   getEvsesSimpleList = () => {
     return request({
       method: 'GET',
       url: `${evses}/simple_list`
     })
   }
}

export default new AssetApi()
