import request from '@/utils/request'

class CrmUsersApi {
  /**
  /**
   * @name CRM Users Dashboard
  */
  getCrmUsersSetup = () => {
    return request({
      method: 'GET',
      url: 'cms/users/setup'
    })
  }

  getCrmStats = () => {
    return request({
      method: 'GET',
      url: 'cms/users/stats'
    })
  }

  getCrmUsersList = (params) => {
    return request({
      method: 'GET',
      url: '/cms/users',
      params
    })
  }

  getCrmUsersdtails = (id) => {
    return request({
      method: 'GET',
      url: `/cms/users/${id}`
    })
  }

  getCrmUsersEditPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/users/${id}/preview`
    })
  }

 putCrmUsersFormData = (id, data) => {
   return request({
     method: 'PUT',
     url: `/cms/users/${id}`,
     data
   })
 }

 postCrmUsersAdd = (data) => {
   return request({
     method: 'POST',
     url: '/cms/users',
     data
   })
 }

  deleteUsersRecord = (id) => {
    return request({
      method: 'DELETE',
      url: `/cms/users/${id}`
    })
  }
}

export default new CrmUsersApi()
