import request from '@/utils/request'

/**
/**
 * @name SynamicLoad
 */

class SynamicLoad {
  getCircuitsSetup = () => {
    return request({
      method: 'GET',
      url: '/cms/circuits/setup'
    })
  }

  getCircuits = () => {
    return request({
      method: 'GET',
      url: '/cms/circuits'
    })
  }

  getCircuitsPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/circuits/${id}/preview`
    })
  }

  getCircuitsAdd = (data) => {
    return request({
      method: 'POST',
      url: '/cms/circuits',
      data
    })
  }

  getCircuitsEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/circuits/${id}`,
      data
    })
  }

  getCircuitsDetail = (id) => {
    return request({
      method: 'GET',
      url: `/cms/circuits/${id}`
    })
  }

  deleteCircuits = (id, data) => {
    return request({
      method: 'DELETE',
      url: `/cms/circuits/${id}`,
      data
    })
  }

  getCircuitsSimpleList = () => {
    return request({
      method: 'GET',
      url: '/cms/circuits/simple_list'
    })
  }

  getUserGroupsSimpleList = () => {
    return request({
      method: 'GET',
      url: '/cms/user_groups/simple_list'
    })
  }

  getUserProirity = (params) => {
    return request({
      method: 'GET',
      url: '/cms/circuit_priorities',
      params
    })
  }

  getUserProirityEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/circuit_priorities/${id}`,
      data
    })
  }

  deleteUserProirity = (id) => {
    return request({
      method: 'DELETE',
      url: `/cms/circuit_priorities/${id}`
    })
  }
}
export default new SynamicLoad()
