import request from '@/utils/request'

/**
/**
 * @name TariffsBillings
 */

class TariffsBillings {
  getTariffsSetup = () => {
    return request({
      method: 'GET',
      url: '/cms/tariffs/setup'
    })
  }

  getTariffs = () => {
    return request({
      method: 'GET',
      url: '/cms/tariffs'
    })
  }

  getTariffsPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/tariffs/${id}/preview`
    })
  }

  getTariffsAdd = (data) => {
    return request({
      method: 'POST',
      url: '/cms/tariffs',
      data
    })
  }

  getTariffsEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/tariffs/${id}`,
      data
    })
  }

  getTariffsDetail = (id) => {
    return request({
      method: 'GET',
      url: `/cms/tariffs/${id}`
    })
  }

  deleteTariffs = (id, data) => {
    return request({
      method: 'DELETE',
      url: `/cms/tariffs/${id}`,
      data
    })
  }

  getTariffsSimpleList = () => {
    return request({
      method: 'GET',
      url: '/cms/tariffs/simple_list'
    })
  }

  // tariff groups
  getTariffGroupsSetup = () => {
    return request({
      method: 'GET',
      url: '/cms/tariff_groups/setup'
    })
  }

  getTariffGroups = () => {
    return request({
      method: 'GET',
      url: '/cms/tariff_groups'
    })
  }

  getTariffGroupsPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/tariff_groups/${id}/preview`
    })
  }

  getTariffGroupsAdd = (data) => {
    return request({
      method: 'POST',
      url: '/cms/tariff_groups',
      data
    })
  }

  getTariffGroupsEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/tariff_groups/${id}`,
      data
    })
  }

  getTariffGroupsDetail = (id) => {
    return request({
      method: 'GET',
      url: `/cms/tariff_groups/${id}`
    })
  }

  deleteTariffGroups = (id, data) => {
    return request({
      method: 'DELETE',
      url: `/cms/tariff_groups/${id}`,
      data
    })
  }

  getTariffGroupsSimpleList = () => {
    return request({
      method: 'GET',
      url: '/cms/tariff_groups/simple_list'
    })
  }

  getUserGroupSimpleList = () => {
    return request({
      method: 'GET',
      url: '/cms/user_groups/simple_list'
    })
  }
}

export default new TariffsBillings()
