import request from '@/utils/request'

class TestApi {
  getOffStoresList = (params) => {
    return request({
      method: 'GET',
      url: '/cms/users/setup',
      params
    })
  }
}

export default new TestApi()
