import request from '@/utils/request'

/**
/**
 * @name Activities
 */
class ActivitiesApi {
  getChargingSessionsSetup = () => {
    return request({
      method: 'GET',
      url: '/cms/charge_sessions/setup'
    })
  }

  getChargingSessionsList = (params) => {
    return request({
      method: 'GET',
      url: '/cms/charge_sessions',
      params
    })
  }

  getChargingSessionsDetail = (id) => {
    return request({
      method: 'GET',
      url: `/cms/charge_sessions/${id}`
    })
  }

  getTransactionsSetup = () => {
    return request({
      method: 'GET',
      url: '/cms/transactions/setup'
    })
  }

  getTransactionsList = (params) => {
    return request({
      method: 'GET',
      url: '/cms/transactions',
      params
    })
  }
}
export default new ActivitiesApi()
