import request from '@/utils/request'

/**
/**
 * @name Configuration
 */

class Configuration {
  getAdiminUsersetup = () => {
    return request({
      method: 'GET',
      url: '/cms/admin_users/setup'
    })
  }

  getAdiminUsers = () => {
    return request({
      method: 'GET',
      url: '/cms/admin_users'
    })
  }

  getAdiminUsersPreview = (id) => {
    return request({
      method: 'GET',
      url: `/cms/admin_users/${id}/preview`
    })
  }

  getAdiminUsersAdd = (data) => {
    return request({
      method: 'POST',
      url: '/cms/admin_users',
      data
    })
  }

  getAdiminUsersEdit = (id, data) => {
    return request({
      method: 'PUT',
      url: `/cms/admin_users/${id}`,
      data
    })
  }

  getAdiminUsersDetail = (id) => {
    return request({
      method: 'GET',
      url: `/cms/admin_users/${id}`
    })
  }
}

export default new Configuration()
