
export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '50'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'no_of_phases',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'max_current_per_phase',
    Object: 'value',
    width: '120'
  }, {
    prop: 'min_current_per_charge_point',
    Object: 'value',
    width: '150'
  }, {
    prop: 'set_charger_limit_to_zero_when_idle',
    Object: 'value',
    width: '200'
  }]
}
